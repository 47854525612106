/* eslint-disable no-param-reassign */
import produce from 'immer';
var samplesBulkKitUpdate = produce(function (draft, action) {
  var _action$payload = action.payload,
    sampleIds = _action$payload.sampleIds,
    kit = _action$payload.kit;
  sampleIds.forEach(function (id) {
    draft[id].kit = kit;
  });
});
export default samplesBulkKitUpdate;