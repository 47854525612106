import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import { sampleTech } from 'utils/constants';
var sampleTemplate = {
  name: null,
  experimentId: null,
  uuid: null,
  type: null,
  createdDate: null,
  lastModified: null,
  complete: false,
  error: false,
  files: {},
  metadata: {},
  options: {},
  kit: null
};

// TODO: Update, this initial state doesn't even match the previously used structure
var sampleFileTemplate = {
  objectKey: '',
  size: 0,
  success: false,
  error: false,
  upload: {
    status: null
  }
};
var defaultSampleOptions = _defineProperty({}, sampleTech.RHAPSODY, {
  includeAbSeq: false
});
var initialState = {
  meta: {
    loading: false,
    error: false,
    saving: false,
    validating: []
  }
};
export default initialState;
export { sampleTemplate, sampleFileTemplate, defaultSampleOptions };